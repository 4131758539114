import debounce from 'lodash/debounce';
import { Filter, FilterSubTypeEnum, FilterTypeEnum, OperatorTypeEnum } from '@/dashboard/common/types/filterInterface';
import { getFilters } from '@/dashboard/common/store/filterStore';
import * as datasetApi from '@/dashboard/api/datasetApi';
import { state as commonState } from '@/dashboard/common/store/commonStore';
import { actions as textAnalysisActions, state as textAnalysisState } from '@/dashboard/textAnalysis/store/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { DimensionDefinition } from '@/domain/dataset/DatasetInterface';
import i18next from 'i18next';
import { toaster } from '@/utils/toaster';
import { reactive } from 'vue';
import { DateRange, DrillDownState, DrilldownType, PolarityType } from '@/dashboard/common/types/drillDownInterface';
import { saveAs } from 'file-saver';
import { EntityTypeEnum } from '@/dashboard/textAnalysis/utils/entityTypes';
import {
    actions as dataSeriesActions,
    getters as dataSeriesGetters,
    state as dataSeriesState
} from '@/dashboard/common/store/dataseriesStore';
import { produce } from 'immer';

dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

const paginationLimit = 25;

const getDefaultState = () => ({
    drillDownTextUnits: [],
    isDrillDownShown: false,
    isDrillDownLoading: false,
    drillDownSearchQuery: '',
    drillDownSort: 'semantic_analysis_id:asc',
    drillDownName: '',
    drillDownFilter: [],
    drillDownType: null,
    drillDownValue: '' as any,
    drillDownPolarity: null,
    drillDownDimensionId: null,
    drillDownBatchNumber: 0,
    isMoreDrillDownLoading: false,
    isDrillDownFinished: false,
    drillDownVerbatimId: null,
    isDrillDownWarningShown: false,
});

export const state = reactive<DrillDownState>(getDefaultState());

export interface EntityValue {
    id: number;
    name: string;
    type: 'label'|'customLabel';
}

interface DriverValue {
    phrase: string;
    driver: string;
    entityId: number;
    entityType: 'label'|'customLabel';
}

interface DateValue {
    date: number;
    dimensionLabelId?: number;
    dimensionCustomLabelId?: number;
    dateRange: DateRange;
}

export interface PhraseValue {
    phrase: string;
    id: number;
}

export interface CustomLabelCategoryValue {
    ids: number[];
}

export interface CategoryValue {
    category: string;
}

export interface NonUniqueValue {
    id: string;
    dictionaryValue: string;
}

interface Segment {
    dimensionId: string|number,
    segment: 'promoter'|'passive'|'detractor'|'satisfied'|'neutral'|'dissatisfied',
    type: 'nps'|'csat'
}

type Value = EntityValue | [EntityValue, EntityValue] | DriverValue | DateValue | PhraseValue | CategoryValue | CustomLabelCategoryValue | NonUniqueValue;

export interface DrillDownSettings {
    type: DrilldownType;
    dimensionId?: string|number;
    value?: Value;
    polarity?: PolarityType;
    segment?: Segment;
    rating?: number|number[];
    multiSeriesId?: string
}

const actions = {
    async drillDown({
        type,
        dimensionId,
        value,
        polarity,
        segment,
        rating,
        multiSeriesId,
    }: DrillDownSettings) {
        try {
            const dimensionIdToDrillDownOn = dimensionId || textAnalysisState.legacyColumnId;

            if (dimensionIdToDrillDownOn == null) throw new Error('no dimensionIdToDrillDownOn');

            const dimension = actions.getDimensionByLegacyId(+dimensionIdToDrillDownOn) || actions.getDimensionById(dimensionIdToDrillDownOn) || actions.getDimensionById(state.drillDownVerbatimId);

            if (!dimension) throw new Error('dimension not found');

            state.drillDownVerbatimId = state.drillDownVerbatimId || textAnalysisState.columnId;
            if (!state.drillDownVerbatimId) {
                const message = 'drillDownVerbatimId is undefined';
                Sentry.addBreadcrumb({ message });
                throw new Error(message);
            }

            state.isDrillDownShown = true;
            state.isDrillDownLoading = true;
            state.drillDownType = type || null;
            state.drillDownDimensionId = dimensionId || null;
            state.drillDownValue = value || null;
            state.drillDownPolarity = polarity || null;

            if (Object.values(textAnalysisState.allEntities)[0].length === 0 && dimensionId) {
                textAnalysisActions.setColumnId(dimensionId.toString());
                await textAnalysisActions.getAllEntities();
            }

            if (segment) {
                if (!segment.dimensionId || !segment.type || !segment.segment) {
                    const message = 'wrong segment info';
                    Sentry.addBreadcrumb({
                        message,
                        data: segment
                    });
                    throw new Error(message);
                }
                actions.addFilter(segment.dimensionId, segment.type === 'nps' ? FilterTypeEnum.npsSegment : FilterTypeEnum.csatSegment, OperatorTypeEnum.oneOf, [segment.segment]);
            }

            if (rating) {
                if ((Array.isArray(rating) && rating.some(r => typeof r !== 'number')) || (typeof rating !== 'number' && !Array.isArray(rating))) {
                    const message = 'wrong rating';
                    Sentry.addBreadcrumb({
                        message,
                        data: rating
                    });
                    throw new Error(message);
                }

                if (Array.isArray(rating)) {
                    actions.addFilter('star_rating_score', FilterTypeEnum.dimension, OperatorTypeEnum.gte, rating[0]);
                    actions.addFilter('star_rating_score', FilterTypeEnum.dimension, OperatorTypeEnum.lte, rating[1]);
                } else {
                    actions.addFilter('star_rating_score', FilterTypeEnum.dimension, OperatorTypeEnum.eq, rating);
                }
            }

            if (polarity && (!['label', 'customLabel', 'relation'].includes(type))) {
                if (!Object.values(PolarityType).includes(polarity)) {
                    const message = 'wrong polarity';
                    Sentry.addBreadcrumb({
                        message,
                        data: polarity
                    });
                    throw new Error(message);
                }
                actions.addFilter(state.drillDownVerbatimId, FilterTypeEnum.polarity, OperatorTypeEnum.oneOf, [polarity]);
            }

            switch (type) {
            case 'category':

                if (!value || !('category' in value) || !value.category) {
                    const message = 'wrong category';
                    Sentry.addBreadcrumb({
                        message,
                        data: value
                    });
                    throw new Error(message);
                }

                state.drillDownName = i18next.t('DASHBOARD.DRILLDOWN.SELECTED_CATEGORY')  + ': ' + value.category;
                actions.addCategoryFilter(dimension, { name: value.category });
                break;
            case 'date': {
                if (!value || !('date' in value) || !value.dateRange) {
                    const message = 'wrong date';
                    Sentry.addBreadcrumb({
                        message,
                        data: value
                    });
                    throw new Error(message);
                }

                if (value.dimensionCustomLabelId) {
                    actions.addFilter(state.drillDownVerbatimId!, FilterTypeEnum.customLabel, OperatorTypeEnum.oneOf, [value.dimensionCustomLabelId]);
                }

                if (value.dimensionLabelId) {
                    actions.addFilter(state.drillDownVerbatimId!, FilterTypeEnum.label, OperatorTypeEnum.oneOf, [value.dimensionLabelId]);
                }

                let format = 'YYYY.MM.DD.';
                if (value.dateRange === 'monthly') {
                    format = 'MM, YYYY';
                } else if (value.dateRange === 'weekly') {
                    format = 'W, YYYY';
                }

                state.drillDownName = i18next.t('DASHBOARD.DRILLDOWN.SELECTED_DATE')  + ': ' + dayjs(value.date).format(format);

                actions.addDateFilter(dimension.id, value.date, value.dateRange);
                break;
            }
            case 'label':
                if (!value || typeof value !== 'object' || !('name' in value) || !value.name || !value.id || !value.type) {
                    const message = 'wrong label';
                    Sentry.addBreadcrumb({ message, data: value });
                    throw new Error(message);
                }

                state.drillDownName = i18next.t('DASHBOARD.DRILLDOWN.SELECTED_LABEL') + ': ' + value.name;

                if (polarity) {
                    actions.addFilter(state.drillDownVerbatimId, FilterTypeEnum.labelPolarity, OperatorTypeEnum.eq, [value.id, polarity]);
                }
                else {
                    actions.addLabelFilter(dimension.id, [value.id]);
                }

                break;
            case 'customLabel':
                if (!value || typeof value !== 'object' || !('name' in value) || !value.name || !value.id || !value.type) {
                    const message = 'wrong customLabel';
                    Sentry.addBreadcrumb({ message, data: value });
                    throw new Error(message);
                }

                state.drillDownName = i18next.t('DASHBOARD.DRILLDOWN.SELECTED_KEYWORD') + ': ' + value.name;

                if (polarity) {
                    actions.addFilter(state.drillDownVerbatimId, FilterTypeEnum.labelPolarity, OperatorTypeEnum.eq, [value.id, polarity]);
                }
                else {
                    actions.addCustomLabelFilter(dimension.id, [value.id]);
                }

                break;
            case 'phrase': {
                if (!value || !('phrase' in value) || !value.phrase) {
                    const message = 'wrong phrase';
                    Sentry.addBreadcrumb({ message, data: value });
                    throw new Error(message);
                }

                state.drillDownName = i18next.t('DASHBOARD.DRILLDOWN.SELECTED_PHRASE', 'Selected phrase') + ': ' + value;
                const phraseId = Object.values(textAnalysisState.phrases)[0].find(e => e.name === value.phrase)?.id;
                if (phraseId) {
                    actions.addPhraseFilter(dimension.id, phraseId);
                }
                else {
                    toaster.error(i18next.t('DASHBOARD.DRILLDOWN.PHRASE_NOT_FOUND', 'Phrase not found, try refreshing the page'));
                    Sentry.addBreadcrumb({
                        message: 'Label not found at Drilldown',
                        data: {
                            phraseId,
                            dimensionId,
                        }
                    });
                    actions.closeDrillDown();
                }
                break;
            }
            case 'relation': {
                if (!value || !Array.isArray(value) || value.some(v => !v.name || !v.id || !v.type)) {
                    const message = 'wrong relation';
                    Sentry.addBreadcrumb({ message, data: value });
                    throw new Error(message);
                }

                if (polarity) {
                    if (!Object.values(PolarityType).includes(polarity)) {
                        const message = 'wrong polarity';
                        Sentry.addBreadcrumb({ message, data: polarity });
                        throw new Error(message);
                    }
                    // the polarity applies to the related entity
                    actions.addFilter(state.drillDownVerbatimId, FilterTypeEnum.labelPolarity, OperatorTypeEnum.eq, [value[1].id, polarity]);
                }
                state.drillDownName = `${i18next.t('DASHBOARD.DRILLDOWN.SELECTED_RELATION')}: ${value.map(v => v.name).join(', ')}`;

                actions.addFilter(dimension.id, FilterTypeEnum.relation, OperatorTypeEnum.allOf, value.map(v => v.id));

                break;
            }
            case 'phraseRelation':
                // TODO maybe later...
                break;
            case 'customLabelCategory': {
                if (!value || !('ids' in value) || !Array.isArray(value.ids)) {
                    const message = 'wrong customLabelCategory';
                    Sentry.addBreadcrumb({ message, data: value });
                    throw new Error(message);
                }

                const entity = Object.values(textAnalysisState.allEntities)[0].find(cl => cl.id === value[0].id);
                if (!entity) {
                    const message = 'entity not found';
                    Sentry.addBreadcrumb({ message, data: value });
                    throw new Error(message);
                }

                const categoryName = entity.categoryName;
                state.drillDownName = i18next.t('DASHBOARD.DRILLDOWN.SELECTED_CUSTOM_LABEL_CATEGORY', 'Selected custom label category') + ': ' + categoryName;
                actions.addCustomLabelFilter(dimension.id, value.ids);
                break;
            }
            case 'driver':
                if (!polarity || !Object.values(PolarityType).includes(polarity) || !value || !('driver' in value) || !value.driver ) {
                    const message = 'wrong driver';
                    Sentry.addBreadcrumb({ message, data: { polarity, value } });
                    throw new Error(message);
                }

                actions.addTextFilter(dimension.id, value.phrase);
                actions.addFilter(state.drillDownVerbatimId, FilterTypeEnum.polarity, OperatorTypeEnum.oneOf, [polarity]);
                if (value.entityType === EntityTypeEnum.customLabel) {
                    actions.addCustomLabelFilter(dimension.id, [value.entityId]);
                } else {
                    actions.addLabelFilter(dimension.id, [value.entityId]);
                }
                state.drillDownName = i18next.t('DASHBOARD.DRILLDOWN.SELECTED_DRIVER', 'Selected driver') + ': ' + value.driver;
                state.isDrillDownWarningShown = true;
                break;
            case 'nonUniqueIdentifier':
                if (!value || !('id' in value) || !('dictionaryValue' in value) || !value.id) {
                    const message = 'wrong data';
                    Sentry.addBreadcrumb({ message, data: { polarity, value } });
                    throw new Error(message);
                }
                actions.addNonUniqueFilter(dimension, value.id);
                state.drillDownName = i18next.t('DASHBOARD.DRILLDOWN.SELECTED_INDIVIDUAL_MARKER', 'Selected individual marker') + ': ' + value.dictionaryValue;
                break;
            case 'rating':
                // rating error is already handled
                state.drillDownName = i18next.t('DASHBOARD.DRILLDOWN.SELECTED_RATING', 'Selected rating') + ': ' + rating;
                break;
            case 'polarity':
                // polarity error is already handled
                switch (polarity) {
                case 'positive':
                    state.drillDownName = i18next.t('DASHBOARD.SUMMARY.POSITIVE_MENTIONS');
                    break;
                case 'neutral':
                    state.drillDownName = i18next.t('DASHBOARD.SUMMARY.NEUTRAL_MENTIONS');
                    break;
                case 'negative':
                    state.drillDownName = i18next.t('DASHBOARD.SUMMARY.NEGATIVE_MENTIONS');
                    break;
                }
            }

            const filters = [...getFilters(), ...state.drillDownFilter];
            if (multiSeriesId && dataSeriesGetters.dataseries.value) {
                const selected = dataSeriesGetters.dataseries.value.find(ds => ds.id === multiSeriesId);
                dataSeriesActions.setSeriesIdForDrilldown(multiSeriesId);
                if (selected) {
                    filters.push(...selected.filters);
                }
            }

            state.drillDownTextUnits = await datasetApi.getTextUnits(
                commonState.dataset.id,
                state.drillDownVerbatimId,
                state.drillDownSearchQuery,
                state.drillDownSort.split(':')[0],
                state.drillDownSort.split(':')[1],
                state.drillDownBatchNumber,
                paginationLimit,
                filters
            );
        } catch (e: any) {
            toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!')); // TODO better error handling later
            Sentry.captureException(e);
        }
        finally {
            state.isDrillDownLoading = false;
        }
    },

    async reloadDrilldown(shouldFullyReload = false) {
        const filters = [...getFilters(), ...state.drillDownFilter];
        if (dataSeriesState.seriesIdForDrilldown && dataSeriesGetters.dataseries.value) {
            const selected = dataSeriesGetters.dataseries.value.find(ds => ds.id === dataSeriesState.seriesIdForDrilldown);
            if (selected) {
                filters.push(...selected.filters);
            }
        }

        if (shouldFullyReload) {
            state.drillDownTextUnits = [];
        }
        state.drillDownTextUnits = await datasetApi.getTextUnits(
            commonState.dataset.id,
            state.drillDownVerbatimId!,
            state.drillDownSearchQuery,
            state.drillDownSort.split(':')[0],
            state.drillDownSort.split(':')[1],
            state.drillDownBatchNumber,
            paginationLimit,
            filters
        );
    },

    getDimensionByLegacyId(id: number|null) {
        return commonState.dataset.dimension_definitions.find(d => d.legacy_id == id);
    },

    getDimensionById(id: string|null|number) {
        return commonState.dataset.dimension_definitions.find(d => d.id == id);
    },

    addFilter(dimensionId: string|number, type: FilterTypeEnum, operator: OperatorTypeEnum, value: null|number|string|any[], hasDateSubtype = false) {
        state.drillDownFilter = [...state.drillDownFilter, {
            dimensionId: dimensionId,
            isMultiSelect: false,
            type: type,
            operator: operator,
            value: value,
            subType: hasDateSubtype ? FilterSubTypeEnum.date : null
        } as Filter];
    },

    addCategoryFilter(dimension: DimensionDefinition, { name }: { name: string }) {
        const dictionary = dimension.dictionary as string[];
        const dictionaryKeys = Object.keys(dictionary);
        const dictionaryValues = Object.values(dictionary);
        const dictionaryIndex = dictionaryValues.findIndex(v => v === name);
        if (dictionaryIndex !== -1) {
            actions.addFilter(dimension.id, FilterTypeEnum.dimension, OperatorTypeEnum.eq, dictionaryKeys[dictionaryIndex]);
        }
    },

    addNonUniqueFilter(dimension: DimensionDefinition, drillDownId) {
        actions.addFilter(dimension.id, FilterTypeEnum.dimension, OperatorTypeEnum.oneOf, [drillDownId]);
    },

    addDateFilter(dimensionId: string, value: number, dateRange: string) {
        const date = dayjs.unix(value/1000);
        let unit = 'day' as 'day'|'week'|'month';
        switch (dateRange) {
        case 'monthly':
            unit = 'month';
            break;
        case 'weekly':
            unit = 'week';
            break;
        }
        const from =  date.startOf(unit === 'week' ? 'isoWeek' : unit);
        const to =  from.add(1, unit);
        actions.addFilter(dimensionId, FilterTypeEnum.dimension, OperatorTypeEnum.between, [from.unix(), to.unix()], true);
    },

    addLabelFilter(dimensionId: string, labelIds: number[]) {
        actions.addFilter(dimensionId, FilterTypeEnum.label, OperatorTypeEnum.allOf, labelIds);
    },

    addPhraseFilter(dimensionId: string, phraseId: number) {
        actions.addFilter(dimensionId, FilterTypeEnum.phrase, OperatorTypeEnum.allOf, [phraseId]);
    },

    addRelationFilter(dimensionId: string, filterType: FilterTypeEnum, value: number[]) {
        actions.addFilter(dimensionId, filterType, OperatorTypeEnum.allOf, value);
    },

    addMixedRelationFilter(dimensionId: string, type: string, value: string) {
        const labelIndex = type === 'customLabelLabelRelation' ? 1 : 0;
        const labelName = value.split(',')[labelIndex];
        const labelId = Object.values(textAnalysisState.allEntities)[0].find(e => e.name === labelName && e.type !== EntityTypeEnum.customLabel)?.id;
        if (!labelId) {
            toaster.error(i18next.t('DASHBOARD.DRILLDOWN.LABEL_NOT_FOUND', 'Label not found, try refreshing the page'));
            Sentry.addBreadcrumb({ message: 'Label not found at Drilldown: ' + labelName });
            actions.closeDrillDown();
            return;
        }
        const customLabelIndex = type === 'customLabelLabelRelation' ? 0 : 1;
        const customLabelName = value.split(',')[customLabelIndex];
        const customLabelId = Object.values(textAnalysisState.allEntities)[0].find(e => e.name === customLabelName && e.type == EntityTypeEnum.customLabel)?.id;
        if (!customLabelId) {
            toaster.error(i18next.t('DASHBOARD.DRILLDOWN.CUSTOM_LABEL_NOT_FOUND', 'Custom label not found, try refreshing the page'));
            Sentry.addBreadcrumb({ message: 'Custom label not found at Drilldown: ' + customLabelName });
            actions.closeDrillDown();
            return;
        }
        actions.addLabelFilter(dimensionId, [labelId]);
        actions.addCustomLabelFilter(dimensionId, [customLabelId]);
    },

    addPhraseRelationFilter(dimensionId: string, value: string) {
        const labelName = value.split(',')[0];
        const labelId = Object.values(textAnalysisState.allEntities)[0].find(e => e.name === labelName)?.id;
        if (!labelId) {
            toaster.error(i18next.t('DASHBOARD.DRILLDOWN.LABEL_NOT_FOUND', 'Label not found, try refreshing the page'));
            Sentry.addBreadcrumb({ message: 'Label not found at Drilldown: ' + labelName });
            actions.closeDrillDown();
        }
        const phraseName = value.split(',')[1];
        // @ts-ignore - relation filter is only possible from Text Analysis
        const phrase = textAnalysisState.filteredEntityRelations[labelName].pos_phrases[phraseName] || textAnalysisState.filteredEntityRelations[labelName].neg_phrases[phraseName];
        const phraseId = phrase.id.toString();
        actions.addFilter(dimensionId, FilterTypeEnum.relation, OperatorTypeEnum.allOf, [labelId, phraseId]);
    },

    addCustomLabelFilter(dimensionId: string, customLabelIds: number[]) {
        actions.addFilter(dimensionId, FilterTypeEnum.customLabel, OperatorTypeEnum.oneOf, customLabelIds);
    },

    addTextFilter(dimensionId: string, value: string) {
        actions.addFilter(dimensionId, FilterTypeEnum.text, OperatorTypeEnum.contains, value);
    },

    setVerbatimId(dimensionId: string|null) {
        state.drillDownVerbatimId = dimensionId;
    },

    closeDrillDown() {
        Object.entries(getDefaultState()).forEach(([key,value]) => {
            state[key] = value;
        });
    },

    setDrillDownSearchQuery: debounce(async (searchQuery) => {
        if (state.drillDownSearchQuery === searchQuery) {
            return;
        }
        state.drillDownSearchQuery = searchQuery;
        await actions.drillDown({
            type: state.drillDownType!,
            dimensionId: state.drillDownDimensionId || undefined,
            value: state.drillDownValue,
            polarity: state.drillDownPolarity || undefined,
        });
    }, 500),

    async setDrillDownSort(sort) {
        state.drillDownSort = sort;
        await actions.drillDown({
            type: state.drillDownType!,
            dimensionId: state.drillDownDimensionId || undefined,
            value: state.drillDownValue,
            polarity: state.drillDownPolarity || undefined,
        });
    },

    async exportPosts() {
        if (!state.drillDownVerbatimId) {
            const message = 'drillDownVerbatimId is undefined';
            Sentry.addBreadcrumb({ message });
            throw new Error(message);
        }

        const dataSourceName = commonState.dataset.name.split('.xlsx')[0].split('.xls')[0];
        const fileName = `${dataSourceName}_drilldown_${(new Date()).toISOString().split('T')[0]}.xlsx`;
        let filters = [...getFilters(), ...state.drillDownFilter];
        if (dataSeriesState.seriesIdForDrilldown) {
            const selected = dataSeriesGetters.dataseries.value.find(ds => ds.id === dataSeriesState.seriesIdForDrilldown);
            if (selected) {
                filters = [...filters, ...selected.filters] as Filter[];
            }
        }
        const response = await datasetApi.exportTextUnits(
            commonState.dataset.id,
            state.drillDownVerbatimId,
            state.drillDownSearchQuery,
            filters as Filter[],
        );
        saveAs(response, fileName);
    },

    addTranslationToTextUnits(semanticAnalysisId: string, translation: string) {
        state.drillDownTextUnits = state.drillDownTextUnits.map((tu) => tu.semantic_analysis_id == semanticAnalysisId ? { ...tu, translation } : tu);
    },

    getMoreDrillDownTextUnits: debounce(async () => {
        if (!state.drillDownVerbatimId) {
            const message = 'drillDownVerbatimId is undefined';
            Sentry.addBreadcrumb({ message });
            throw new Error(message);
        }

        if (state.isDrillDownFinished || state.drillDownTextUnits.length < paginationLimit) {
            return;
        }
        if (state.drillDownTextUnits.length > 0) {
            state.isMoreDrillDownLoading = true;
            state.drillDownBatchNumber = state.drillDownBatchNumber + 1;
            let filters = [...getFilters(), ...state.drillDownFilter];
            if (dataSeriesState.seriesIdForDrilldown) {
                const selected = dataSeriesGetters.dataseries.value.find(ds => ds.id === dataSeriesState.seriesIdForDrilldown);
                if (selected) {
                    filters = [...filters, ...selected.filters] as Filter[];
                }
            }
            const response = await datasetApi.getTextUnits(
                commonState.dataset.id,
                state.drillDownVerbatimId,
                state.drillDownSearchQuery,
                state.drillDownSort.split(':')[0],
                state.drillDownSort.split(':')[1],
                state.drillDownBatchNumber * paginationLimit,
                paginationLimit,
                filters as Filter[]
            );
            if (response.length < paginationLimit) {
                state.isDrillDownFinished = true;
            }
            state.drillDownTextUnits = [ ...state.drillDownTextUnits, ...response ];
            state.isMoreDrillDownLoading = false;
        }
    }, 200),

    async getTopEntities() {
        if (!state.drillDownVerbatimId) {
            const message = 'drillDownVerbatimId is undefined';
            Sentry.addBreadcrumb({ message });
            throw new Error(message);
        }

        let filterToApply = [...getFilters(), ...state.drillDownFilter] as Filter[];
        if (dataSeriesState.seriesIdForDrilldown) {
            const selected = dataSeriesGetters.dataseries.value.find(ds => ds.id === dataSeriesState.seriesIdForDrilldown);
            if (selected) {
                filterToApply = [...filterToApply, ...selected.filters] as Filter[];
            }
        }
        if (state.drillDownSearchQuery.length > 0) {
            filterToApply.push({
                dimensionId: state.drillDownVerbatimId,
                isMultiSelect: false,
                type: FilterTypeEnum.text,
                operator: OperatorTypeEnum.contains,
                value: state.drillDownSearchQuery
            });
        }
        return await datasetApi.getTopEntities(
            commonState.dataset.id,
            state.drillDownVerbatimId,
            filterToApply
        );
    },

    removeLabelFromTextUnit: ({ textUnitId, entityId, entitySubtype }: { textUnitId: string, entityId: number, entitySubtype: string }): void => {
        state.drillDownTextUnits = produce(state.drillDownTextUnits, (draft) => {
            const textUnit = draft.find(tu => tu.semantic_analysis_id === textUnitId);
            // @ts-ignore
            textUnit.entities = textUnit.entities.filter(e => e.id !== entityId || e.subtype !== entitySubtype);
        });
    },

    changeLabelOIOnTextUnit: ({ textUnitId, entityId, entitySubtype, value }: { textUnitId: string, entityId: number, entitySubtype: string, value: number }): void => {
        state.drillDownTextUnits = state.drillDownTextUnits.map(tu => {
            if (tu.semantic_analysis_id !== textUnitId) return tu;

            const newEntities = tu.entities.map(e => {
                if (e.subtype !== entitySubtype || e.id !== entityId) return e;

                return {
                    ...e,
                    opinionIndex: value
                };
            });

            return {
                ...tu,
                entities: newEntities
            };
        });
    },
};

export default function useDrillDownStore() {
    return {
        state,
        actions: actions,
    };
}
